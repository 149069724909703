<template>
  <div style="width: 100vw;height: 100vh;background-color: #F3F5F9;">
            <!-- <div class="rightView"></div> -->
            <img class="loginBackImg" src="../../assets/loginBg.jpg">
    <div
        style="box-sizing: border-box;padding-left: 20px;padding-right:20px;
        margin-top: 31vh;
        margin-left: 33vw;
        width: 30vw;height: 30vh;display: flex;
        flex-direction: column;justify-content: center;
        background-color: white;  position: absolute;
        border-radius: 10px;">


      <!-- <div class="login-title-big">Remote Exam</div> -->
      <!-- <img class="loginLogo" src="../../assets/WAO-logo.png"> -->

      

      <div class="login-title" style="margin-top: 10px">Email</div>

      <input class="login-input" v-model="form.account_name"/>



      <div v-if="form.type == 'Admin'" class="login-title" style="margin-top: 1vh">Password</div>
      <input v-if="form.type == 'Admin'" class="login-input" v-model="form.account_pwd"/>


      <div v-if="form.type == '学生'" class="login-title" style="margin-top: 1vh">Verification Code</div>

      <div v-if="form.type == '学生'" class="codeView">


        <input class="login-input2" v-model="form.account_pwd"/>

       
        <div v-if="remainingTime==60" class="sendCodeView" @click="sendCode()">Send Code</div>
        <div v-if="remainingTime!==60" class="sendCodeView">{{remainingTime}}</div>


      </div>

      


      <div class="login-title" style="margin-top: 1vh">Login Type</div>


      <div class="codeView">

        <div class="login-input2" style="padding: 0 25px;">
            <el-select id="my-select" type="text" :popper-append-to-body="false" class="loginInput" placeholder="Identity" v-model="form.type" style="width:100%;">
                <el-option value="学生" label="Student"></el-option>
                <el-option value="Admin" label="Admin"></el-option>
                <!-- <el-option value="助教" label="Teaching Assistant"></el-option> -->
            </el-select>
        </div>

        <div class="sendCodeView" @click="submit">Log In</div>

      </div>

      
      <!-- <div style="margin-top: 40px;position: relative">
        <div style="position: absolute;right: 0">
          <img @click="submit" src="../../assets/login-arrow.png" style="cursor: pointer;width: 3vw;height: auto">
        </div>

      </div> -->
 

    </div>
  </div>
</template>

<script>
import {loginByEducator, loginByStudent, loginByEmployee,createLoginPinCode,verifyLoginPin} from '../../api/index'
import {
  setUserId,
  setUserName,
  setUserType,
  getProjectCode,
  setProjectCode,
  setProjectName,
  setApplicationExtraEvents,
  setAppId,
  setAccountName,
  setAccountCountry,
  setAccountContinent,
  setApplicationEvents, setAvatarUrl,logout
} from '../../utils/store'
import '../../assets/common/font.css'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        account_name: '',
        account_pwd: '',
        type: '学生'
      },
      remainingTime: 60, // 初始剩余时间设为60秒
      timer: null,

    }
  },
  mounted() {
    console.log('测试自动部署')
    //先清除一下数据
    logout()
  },
  beforeDestroy() {
    clearTimeout(this.timer); // 组件销毁前清除定时器
  },
  methods: {
    submit() {

      // this.form.account_name = 'yinzehu@sina.cn'
      // this.form.account_pwd = 'impact'

      if (!this.form.account_name) {
        this.$message.error('Please input email')
        return
      }

      if (!this.form.account_pwd) {
        this.$message.error('Please input password')
        return
      }

      if (this.form.type === 'Admin') {

        if (this.form.account_name = 'admin' && this.form.account_pwd == 'yiquanTodo'){

            setUserId('admin')
            setUserType('admin')
            setUserName('admin')
            this.$router.push('/adminhome')


            return

        }

        // loginByEducator(this.form.account_name, this.form.account_pwd).then(res => {
        //   if (res.data.code == 0) {
        //     this.$message({
        //       message: 'login success',
        //       type: 'success'
        //     })
        //     setUserId(res.data.data._id.$id)
        //     setUserName(res.data.data.educator_first_name + res.data.data.educator_last_name)
        //     setUserType('educator')
        //     this.$router.push('/teacherlist')
        //   } else {
        //     this.$message.warning('login fail')
        //   }
        // })
      } else if (this.form.type === '学生') {
        verifyLoginPin(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'Login Successful',
              type: 'success'
            })
            var result = res.data.data
          
            console.log(1111111,result)
            setUserId(result._id.$id)
            setUserType('student')
            var student_name = res.data.data.student_givenName_pinyin+' ' + res.data.data.student_lastName_pinyin
            setUserName(student_name)


            setAccountName(res.data.data.account_name)
            setAccountCountry(res.data.data.account_country)
            setAccountContinent(res.data.data.account_continent)

            this.$router.push('/home')
          } else {
            this.$message.warning(res.data.msg)
          }
        })
        // loginByStudent(this.form.account_name, this.form.account_pwd).then(res => {
        //   if (res.data.code == 0) {
        //     this.$message({
        //       message: 'login success',
        //       type: 'success'
        //     })
        //     var result = res.data.data
          
        //     console.log(1111111,result)
        //     setUserId(result._id.$id)
        //     setUserType('student')
        //     var student_name = res.data.data.student_givenName_pinyin+' ' + res.data.data.student_lastName_pinyin
        //     setUserName(student_name)


        //     setAccountName(res.data.data.account_name)

        //     this.$router.push('/home')
        //   } else {
        //     this.$message.warning('Login failed: ' + res.data.msg)
        //   }
        // })
      } else if (this.form.type === '助教') {
        loginByEmployee(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'Login Successful.',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserType('employee')
            setUserName(res.data.data.user_lastName + res.data.data.user_givenName)
            this.$router.push('/employeelist')
          } else {
            this.$message.warning('login fail')
          }
        })
      }

    },

    sendCode(){
      createLoginPinCode(this.form.account_name).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'Verification code sent successfully.',
              type: 'success'
            })

            this.countdown()
            
          } else {
            this.$message.warning('Verification code sent failed.')
          }
        })
    },
    countdown() {
      if (this.remainingTime > 0) {
        this.remainingTime -= 1;
        this.timer = setTimeout(this.countdown, 1000); // 每秒调用一次
      } else {
        clearTimeout(this.timer); // 时间结束，清除定时器
        this.remainingTime == 60
      }
    }
  }
}
</script>

<style lang="less">

.loginBackImg {
  // 1650 × 2028
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 2;
  top: 0;
  align-items: center;
  right: 0;
  // left: 0;
}
.rightView {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
  background-color: #4468EC;
}
.loginLogo {
  width: 30%;
  // height: 30%;
  position: absolute;
  // z-index: -1;
  top: 70px;
  align-items: center;
  left: 35%;
}

.login-title-big {
  position: absolute;
  color: #4468EC;
  // background-color: #E74646;
  font-size: 43px;
  font-weight: 600;
  font-family: "Montserrat-SemiBold";
  text-align: center;
  top: 100px;
  left: 16vw;
  width: 33vw;
}
.login-title {
  color: #273161;
  font-size: 1vw;
  font-weight: 400;
  font-family: "PingFang SC";
}
.codeView{
  width: 100%;
  height: 5vh;
  // background-color: #4468EC;
}
.sendCodeView{
  float: left;
  width: 25%;
  margin-left: 10px;
  margin-top: 0.5vh;
  background-color: #273161;
  color: white;
  font-size: 15px;
  height: 4vh;
  text-align: center;
  line-height: 4vh;
  border-radius: 5px;
  cursor: pointer;
}
.login-input2 {
  margin-top: 2px;
  box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-color:#273161;
  color: #273161;
  font-size: 13px;
  width: 70%;
  height: 5vh;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 40px;
  float: left;
  // background-color: red;
}
.login-input {
  margin-top: 2px;
  box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-color:#273161;
  color: #273161;
  font-size: 13px;
  width: 100%;
  height: 5vh;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 40px;
  // background-color: red;
}
.login-input1 {
  margin-top: 2px;
  box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-color:#333333;
  color: #333333;
  font-size: 1.1vw;
  width: 100%;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 0 0 25px;
}
.loginInput {
  background-color: transparent;
}

.el-select-dropdown__item.selected {
  color: rgb(243, 152, 0);
  font-weight: 700;
}
.el-select{
    width: 100%;
    color: #333333;
    display: inline-block;
    input{
        font-size: 12px;
        border: none;
        background: none;
        text-align: left;
        color: #333333;
        // font-weight: bold;
    }
    .el-input__suffix{
        i{
            color: transparent;
            margin-left: 0.06rem;
            font-weight: bold;
        }
    }
    /* WebKit browsers */
    ::-webkit-input-placeholder {
        color: transparent;
        font-weight: bold;
    }
}


</style>
